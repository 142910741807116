import React from 'react';
import styled from '@emotion/styled';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import { SEO, TagBlock } from 'components';

const Article = styled.article`
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 11rem;
    border-radius: 10px;
    position:relative;
    background: ${props => props.theme.colors.background.dark};
    transition: all .3s ease;
    box-shadow: 0 1px 1px 0 rgba(31, 35, 46, .15);
`;

const PostBlockContent = styled.div`
    padding: 0;
    margin: 0;
    width: 100%;
    border-radius: 10px;
    color: ${props => props.theme.colors.primary.base};
`;

const PostTitleBlock = styled.div`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1rem;
    background: ${props => props.theme.colors.background.light};
`;

const PostTitle = styled.h1`
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 400;
    color: ${props => props.theme.colors.primary.light};
`;

const PostBlockBottom = styled.div`
    padding: 1rem;
`;

const PostContent = styled.div`
    margin-top: 0;
    a {
        color: ${props => props.theme.colors.link};
        &:hover {
            color: ${props => props.theme.colors.linkHover};
        }
    }
`;

const PostInfo = styled.span`
    font-size: 12px;
    background: #d24d04;
    border: 1px solid #ff5a00;
    color: #fff;
    display: block;
    line-height: 1;
    position: absolute;
    top: -10px;
    left: -10px;
    text-shadow: #000 1px 1px 2px;
    box-shadow: 0.2em 0.2em 0.3em rgba(0, 0, 0, 0.4);
    padding: 2px;
`;

const Suggestions = styled.div`
    box-shadow: ${props => props.theme.shadow.suggestion};
`;

const PostBlockSpacer = styled.div`
    background: ${props => props.theme.colors.background.medium};
    padding:0.5rem 0;
`;

const SuggestionBar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: ${props => props.theme.colors.background.dark};
  border-top: 1px solid #777;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0 2rem;
`;
const PostSuggestion = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  a {
    color: ${props => props.theme.colors.link};
    &:hover {
        color: ${props => props.theme.colors.linkHover};
    }
}
`;

const Post = ({ data, pageContext }) => {
    const post = data.markdownRemark;
    const title = post.frontmatter.title;
    const date = post.frontmatter.date;
    const author = post.frontmatter.author;
    const html = post.html;
    const tags = post.frontmatter.tags;
    const { previous, next, slug } = pageContext;

  return (
    <Layout title={title}>
        <SEO
            title={title}
            pathname={slug}
            article
        />
        <Article key={post.id}>
            <PostBlockContent>
                <PostTitleBlock>
                    <PostTitle>
                        {title}
                    </PostTitle>
                    <PostInfo>
                        Posted by {author} on {date}
                    </PostInfo>
                    <TagBlock tags={tags} />
                </PostTitleBlock>
                <PostBlockBottom>
                    <PostContent dangerouslySetInnerHTML={{ __html: html }} />
                </PostBlockBottom>
                <Suggestions>
                <PostBlockSpacer />
                    <SuggestionBar>
                        <PostSuggestion>
                            {previous &&  (
                                <>←&nbsp;
                                <Link to={previous.fields.slug}>
                                    {previous.frontmatter.title}
                                </Link>
                                </>
                            )}
                        </PostSuggestion>
                        <PostSuggestion>
                            {next && (
                                <>
                                <Link to={next.fields.slug}>
                                    {next.frontmatter.title}
                                </Link>
                                &nbsp;→
                                </>
                            )}
                        </PostSuggestion>
                    </SuggestionBar>
                    </Suggestions>
            </PostBlockContent>
        </Article>
    </Layout>
  )
}

export default Post;

Post.propTypes = {
    pageContext: PropTypes.shape({
      prev: PropTypes.object,
      next: PropTypes.object,
    }).isRequired,
    data: PropTypes.object.isRequired,
  };

  export const query = graphql`
      query PostBySlug($slug: String!) {
          markdownRemark(fields: { slug: {eq: $slug } }) {
              html
              id
              frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  author
                  title
                  tags
              }
          }
      }
  `;